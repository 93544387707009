<template>
  <section>
    <b-card v-loading="loading" class="pre-sale">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div>
        <b-row>
          <b-col>
            <el-select
              clearable
              style="width: 30%"
              class="per-page-selector d-inline-block mr-1"
              v-model="searchQuery.theatre"
              filterable
              placeholder="請選擇戲院"
            >
              <el-option
                v-for="item in selectTheatres"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <b-button variant="primary" @click="search()"> Search </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="sub-title">
              由現在起查閱14天後的預售數：({{ preSaleDate }}後)
            </p>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-table
          bordered
          hover
          responsive
          :items="items"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : fields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              fields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template #cell(theatre_name)="data">
            <div style="width: 100px">
              {{ data.item.theatre.theatre_name }}
            </div>
          </template>
          <template #cell(title_name)="data">
            <div @click="goPage(data.item, 'checkXML')" style="width: 150px">
              {{ data.item.title_name }}
            </div>
          </template>
          <template #cell(show_time)="data">
            <div class="text-nowrap">
              {{ $moment(data.item.show_time).format("DD-MM-YYYY HH:mm:ss") }}
            </div>
          </template>
          <template #cell(date_of_engagement)="data">
            <div class="text-nowrap">
              {{ $moment(data.item.date_of_engagement).format() }}
            </div>
          </template>
          <template #cell(total_gross)="data">
            <div class="text-nowrap">
              {{ parseFloat(data.item.total_gross).toLocaleString() }}
            </div>
          </template>
          <template #cell(total_admissions)="data">
            <div class="text-nowrap">
              {{ parseFloat(data.item.total_admissions).toLocaleString() }}
            </div>
          </template>
          <template #cell(date_to_report)="data">
            <div class="text-nowrap">
              {{
                data.item.date_to_report === "Invalid date"
                  ? "-"
                  : $moment(data.item.date_to_report).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
              }}
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
  </section>
</template>
<script>
import moment from "moment";
import { getPreSale } from "@/api/boxoffice";
import { getTheatreList2 } from "@/api/theatre";
export default {
  components: {},

  data() {
    return {
      loading: false,
      perPage: 10,
      filter: null,
      title: "查閱14日後預售數",
      filterOn: [],
      selectValue: "全部地區",
      selectOptions: ["全部地區", "香港", "非香港"],
      searchQuery: {
        theatre: "",
        location: "全部地區"
      },
      location: "",
      theatre_id: "",
      selectTheatres: [],
      date: new Date().toISOString().split("T")[0],
      fields: [
        {
          key: "id",
          label: "E_ID",
          sortable: true
        },
        {
          key: "theatre_name",
          label: "Theatre Name",
          sortable: true
        },
        {
          key: "screen_id",
          label: "House",
          sortable: true
        },
        {
          key: "film_master_id",
          label: "F_M ID",
          sortable: true
        },
        {
          key: "film_id",
          label: "Film ID",
          sortable: true
        },
        {
          key: "cinema_id",
          label: "POS ID",
          sortable: true
        },
        {
          key: "title_name",
          label: "Film Name",
          sortable: true
        },
        {
          key: "date_of_engagement",
          label: "Date of Eng.",
          sortable: true
        },
        {
          key: "show_time",
          label: "Show Time",
          sortable: true
        },
        {
          key: "total_admissions",
          label: "Total ADM.",
          sortable: true
        },
        {
          key: "total_gross",
          label: "Total B.O.",
          sortable: true
        },
        {
          key: "date_to_report",
          label: "Upload Date",
          sortable: true
        }
      ],
      addButtonTitle: "",
      editButtonTitle: "",
      items: [],
      currentPage: 1,
      pageTotal: 10,
      rows: 10
    };
  },
  computed: {
    preSaleDate() {
      const date = moment();
      const fut = date.add(14, "days").format();
      return fut;
    },
    dataListStart() {
      if (this.rows === 5) {
        return (this.currentPage - 1) * 5 + 1;
      } else {
        return (this.currentPage - 1) * 2 * (this.rows / 2) + 1;
      }
    },
    dataListStop() {
      if (this.currentPage === Math.ceil(this.pageTotal / this.rows)) {
        return this.pageTotal;
      } else {
        return this.rows * this.currentPage;
      }
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getPreSale();
    this.getTheatreList("");
  },
  methods: {
    goPage(item, type) {
      if (type === "checkXML") {
        this.$router.push({
          name: "xml-related-record",
          query: {
            file_id: item.xml_file_id,
            xml_id: item.theatre.theatre_no,
            e_id: item.id
          }
        });
      }
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getPreSale();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPreSale();
    },
    handleSearch() {
      this.currentPage = 1;
      this.getPreSale();
    },
    search() {
      this.getPreSale();
    },
    getTheatreList(search) {
      this.loading = true;
      const data = `page=1&count=10000&search=${search}&area=${
        window.sessionStorage.getItem("area") || ""
      }`;
      getTheatreList2(data)
        .then((res) => {
          const arr = [];
          const sortList = res.data.sort((a, b) =>
            a.full_name.localeCompare(b.full_name)
          );
          sortList.forEach((el) => {
            const obj = new Object();
            obj.value = el.id;
            obj.text = el.full_name;
            arr.push(obj);
          });
          this.selectTheatres = arr;
          this.loading = false;
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getPreSale() {
      this.loading = true;
      const data = `page=${this.currentPage}&count=10000&area=${
        window.sessionStorage.getItem("area") || ""
      }&date=${this.date}&theatre_id=${this.searchQuery.theatre}`;
      console.log(data);
      getPreSale(data)
        .then((res) => {
          this.items = res.data;
          console.log(this.items);
          this.items.map((el) => {
            el.date_to_report = el.xml_file.date_to_report;
          });
          console.log(this.items);
          this.pageTotal = res.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
}
.search {
  display: flex;
  margin: 15px 0;
}
.table-name:hover {
  text-decoration: underline;
}
</style>
